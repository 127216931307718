import { DialogConfig } from '@ui/shared/models';

export const schufaPaymentMoreInformation: DialogConfig = {
  message: 'schufa.schufa_payment_more_information_description_l',
  titleMessage: 'schufa.schufa_payment_more_information_title_l'
};

export const schufaAddressNoHouseNumber: DialogConfig = {
  message: 'schufa.schufa_no_housenumber_message_l',
  titleMessage: 'schufa.schufa_no_housenumber_title_l'
};
